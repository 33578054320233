@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Cabin", sans-serif;
}

p,
small,
input,
select,
div,
button,
a {
  font-family: "Cabin", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer components {
  .page_title {
    @apply font-semibold text-yankee_blue text-lg s:text-xl capitalize;
  }

  .page_subtitle {
    @apply font-semibold text-yankee_blue text-sm s:text-lg;
  }

  .page_title_container {
    @apply flex items-center gap-2;
  }

  .side_page_title {
    @apply font-semibold text-yankee_blue text-base mb-5;
  }

  .sidebar_inactive {
    @apply text-auro_metal_saurus text-sm z-50;
  }

  .sidebar_active {
    @apply text-primary text-sm z-50;
  }

  .main_content {
    @apply bg-white shadow-sm py-5;
  }

  .main_content_header {
    @apply bg-white shadow-sm mb-5 py-2 flex items-center gap-1 min-w-[730px] px-2;
  }

  .main_content_header_btn {
    @apply ml-1 text-sm py-1 px-2 font-thin text-morning_blue border-white hover:border-morning_blue hover:text-independence border-b-4;
  }

  .main_content_header_btn_active {
    @apply text-independence font-bold border-b-4 border-primary;
  }

  .main_content_header_line {
    @apply w-[1px] h-5 bg-anti_flash_white;
  }

  .page {
    @apply py-[2%] px-3 s:px-5 xl:mr-[300px];
  }

  .page_dark_overlay {
    @apply fixed top-0 left-0 bottom-0 xl:hidden right-[300px] bg-black/50;
  }

  .page_right_bar {
    @apply bg-white fixed top-0 bottom-0 right-0 w-full min-[501px]:max-w-[300px] min-w-[300px] hidden xl:block overflow-auto py-[2%] px-3;
  }

  .auth_page {
    @apply md:grid md:grid-cols-2 min-h-screen;
  }

  .auth_logo_wrapper {
    @apply flex justify-center;
  }

  .auth_page_content_grid {
    @apply px-[10%] py-[4%];
  }

  .auth_page_content {
    @apply max-w-sm mx-auto;
  }

  .auth_page_title {
    @apply text-yankee_blue text-xl text-center font-semibold mt-[10%] mb-5;
  }

  .auth_page_subtitle {
    @apply mb-10 text-yankee_blue text-base;
  }

  .auth_page_image_grid {
    @apply bg-primary_light_20 hidden md:flex items-center;
    /* @apply bg-primary col-span-2; */
  }

  .auth_image_container {
    @apply max-w-[90%] mx-auto;
  }

  .profile {
    @apply py-3;
  }

  .profile_title {
    @apply font-bold text-yankee_blue mb-3 text-lg capitalize flex items-center gap-4;
  }

  .profile_subtitle {
    @apply font-bold text-yankee_blue mb-2 text-base capitalize;
  }

  .profile_data {
    @apply grid sm:grid-cols-2;
  }

  .table_wrapper {
    @apply overflow-auto max-w-[100vw];
  }

  .table_header {
    @apply min-w-[700px] grid grid-cols-4 gap-1 border-b pb-5;
  }

  .table_cell_round_btn {
    @apply border;
  }

  .table_row {
    @apply cursor-pointer;
  }

  .active_table_row {
    @apply bg-primary_light_30;
  }

  .table_icon {
    @apply text-lg p-2 text-primary rounded-full hover:border-none hover:bg-primary hover:text-white;
  }

  .item_paid {
    @apply px-2 py-[2px] w-[50%] text-center rounded-sm bg-primary !text-white capitalize;
  }

  .item_pending {
    @apply px-2 py-[2px] w-[50%] text-center rounded-sm bg-saffron !text-white capitalize;
  }

  .item_overdue {
    @apply px-2 py-[2px] w-[50%] text-center rounded-sm bg-congo_pink !text-white capitalize;
  }

  /* Calendar */
  /* .big-calendar .rbc-btn-group {
    @apply mb-2;
  }

  .big-calendar .rbc-toolbar {
    @apply mt-5;
  }

  .big-calendar .rbc-toolbar button {
    @apply text-xs border-greyX11 rounded-none p-0 w-8 h-8 inline-flex items-center justify-center;
  }

  .big-calendar .rbc-toolbar .rbc-toolbar-label {
    @apply text-base font-semibold md:text-lg mb-2;
  }

  .big-calendar .rbc-month-view {
    @apply border-none mt-5;
  }

  .big-calendar .rbc-month-header .rbc-header {
    @apply border-none text-sm font-normal;
  }

  .big-calendar .rbc-month-row {
    @apply mb-3;
  }

  .big-calendar .rbc-day-bg,
  .big-calendar .rbc-month-row {
    @apply !border-none !bg-transparent;
  }

  .big-calendar .rbc-date-cell {
    @apply text-center;
  }

  .big-calendar .rbc-row:first-child {
    background-color: #f2f2f2;
    @apply rounded-2xl mb-1 py-1;
  }

  .big-calendar .rbc-month-header {
    @apply !mb-5 border-none !bg-transparent;
  }

  .big-calendar .rbc-date-cell button {
    color: #252525;
    @apply rounded-full p-1 w-7 h-7 text-xs md:text-sm font-light;
  }

  .big-calendar .rbc-now button {
    @apply bg-black text-white font-normal;
  }

  .eventPropGetter {
    @apply text-[9px] s:text-[10px] sm:text-xs rounded-sm font-semibold;
  } */

  /* remove borders */
  .big-calendar .rbc-day-bg,
  .big-calendar .rbc-month-row {
    @apply !border-none !bg-transparent;
  }

  .big-calendar .rbc-month-header .rbc-header {
    @apply border-none !text-xs font-normal !text-slate_grey;
  }

  .big-calendar .rbc-month-header {
    @apply !mb-5 border-none !bg-transparent;
  }

  .big-calendar .rbc-month-header .rbc-header {
    @apply border-none text-sm font-normal;
  }

  .big-calendar .rbc-month-view {
    @apply border-none mt-5;
  }

  /* format date color */
  .big-calendar .rbc-button-link {
    font-family: "Cabin", sans-serif;
    @apply !text-yankee_blue;
  }

  .big-calendar .rbc-date-cell {
    font-family: "Cabin", sans-serif;
    @apply text-center !text-xs !font-light !h-8 !p-0 flex items-center justify-center;
  }

  .big-calendar .rbc-date-cell button {
    font-family: "Cabin", sans-serif;
    @apply !font-light !text-davys_Grey;
  }

  .big-calendar .rbc-off-range button {
    font-family: "Cabin", sans-serif;
    @apply !text-manatee;
  }

  .big-calendar .rbc-now {
    @apply !bg-primary !text-white rounded-lg;
  }

  .big-calendar .rbc-now button {
    @apply !text-white;
  }

  .big-calendar .rbc-row-segment {
    @apply flex items-center justify-center;
  }

  .eventPropGetter {
    @apply !h-[1px] !w-[1px];
  }
  /* End of calendar */
}
